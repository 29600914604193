import { StorableMfe, EventNames } from '@storable/mfe';

const MFE_TITLE = 'Payments Portal';
const LOCAL_STORAGE_TOKEN_KEY = 'token';
const LOCAL_STORAGE_FACILITY_REPORTING_KEY = 'isFacilityLevelReportingEnabled';
const LOCAL_STORAGE_REPORTING_IDS_KEY = 'reportingFacilityIds';
const EVENT_TYPE_FACILITY_LEVEL_REPORTING = 'facilityLevelReporting';
const DEBUG_AUTH_TOKEN_RECEIVED = 'Auth token has been received';
const DEBUG_FACILITY_REPORTING_RECEIVED =
    'facilityLevelReporting event has been received';
const DEBUG_MFE_STARTED = 'MFE provider started';
const DEBUG_REQUESTED_TOKEN = 'Requested token';
const ERROR_REQUEST_TOKEN = 'Error requesting token: ';

let mfe: StorableMfe | null = null;

export const initializeMFEProvider = async () => {
    mfe = new StorableMfe(MFE_TITLE);

    mfe.addEventListener(EventNames.APPLY_AUTH_TOKEN, (event: Event) => {
        const message = (event as unknown as CustomEvent).detail;
        const { token } = message.payload;
        window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
        console.debug(DEBUG_AUTH_TOKEN_RECEIVED);
    });

    mfe.addEventListener(EventNames.APP_MESSAGE, (event: Event) => {
        const message = (event as unknown as CustomEvent).detail;
        const { type, data } = message.payload;

        if (type === EVENT_TYPE_FACILITY_LEVEL_REPORTING) {
            let isFacilityLevelReportingEnabled =
                data.isFacilityLevelReportingEnabled;
            const reportingFacilityIds = data.reportingFacilityIds || null;

            isFacilityLevelReportingEnabled =
                isFacilityLevelReportingEnabled &&
                Array.isArray(reportingFacilityIds) &&
                reportingFacilityIds.length > 0;

            window.localStorage.setItem(
                LOCAL_STORAGE_FACILITY_REPORTING_KEY,
                JSON.stringify(isFacilityLevelReportingEnabled)
            );
            window.localStorage.setItem(
                LOCAL_STORAGE_REPORTING_IDS_KEY,
                JSON.stringify(reportingFacilityIds)
            );

            console.debug(DEBUG_FACILITY_REPORTING_RECEIVED);
        }
    });

    await mfe.start({
        title: MFE_TITLE,
        initialSize: { width: 500, height: 600 },
        minimumSize: { width: 300, height: 300 },
        fullscreen: false,
        allowResize: true,
    });
    console.debug(DEBUG_MFE_STARTED);

    try {
        mfe.requestAuthToken();
        console.debug(DEBUG_REQUESTED_TOKEN);
    } catch (error) {
        console.error(ERROR_REQUEST_TOKEN, error);
    }
};
